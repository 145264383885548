import React, { useEffect, useState } from "react";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { getUserList } from "../../../services/api_function";
import { FaEye } from "react-icons/fa";

function UserList() {
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const [search, setSearch] = useState(0);
  const [activeTab, setActiveTab] = useState("TOTAL");

  const fetchUser = async (search, it1) => {
    console.log(it1, "::125263632");
    try {
      const res = await getUserList(search);
      if (res) {
        console.log(res, "::::");
        // setUserList(res);
        const d = res.filter((it) => {
          if (it?.deposits?.deposits > 0 && it1 == 1) {
            // console.log("in If");
            return it;
          } else if (it?.deposits?.deposits == 0 && it1 == 2) {
            return it;
          } else if (it1 == 0) {
            return it;
          }
        });
        // console.log(d);
        setUserList(d);
      }
    } catch (error) {
      console.log(error, "in fetchUser");
    }
  };

  useEffect(() => {
    fetchUser(search,0);
  }, [search]);

  const totalPages = Math.ceil(userList.length / itemsPerPage);

  const paginatedData = userList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="row col-lg-12 mainDiv table-responsive">
      <div className="row justify-content-between mb-3">
        <h2 style={{ width: "fit-content" }}>User List</h2>
        <input
          type="text"
          placeholder="Search from phone number"
          style={{ width: "fit-content", height: "35px", borderRadius: "10px" }}
          onChange={(e) => {
            fetchUser(e.target.value,0);
            setSearch(e.target.value);
          }}
        />
      </div>

      <div className="col-lg-12 row d-flex gap-2 mb-3">
        <button
          className="btn btn-primary"
          style={{ width: "fit-content" }}
          onClick={() => setActiveTab("TOTAL")}
        >
          Total
        </button>
        <button
          className="btn btn-info"
          style={{ width: "fit-content" }}
          onClick={() => {
            setActiveTab("ACT");
            setCurrentPage(1)
            fetchUser(search, 1);
          }}
        >
          Active
        </button>
        <button
          className="btn btn-success "
          style={{ width: "fit-content" }}
          // disabled
          onClick={() => {
            setActiveTab("INC");
            setCurrentPage(1)
            fetchUser(search, 2);
          }}
        >
          In Active
        </button>
      </div>

      <table className="table table-hover" style={{ height: "fit-content" }}>
        <thead>
          <tr>
            <th className="col text-center">S. no</th>
            <th scope="col">Name</th>
            <th scope="col">Contact no</th>
            <th scope="col">Total Deposit</th>
            <th scope="col">Total Withdrawal</th>
            <th scope="col">Total Pending</th>
            <th scope="col">Refrral Income</th>
            <th scope="col">Referral Code</th>
            <th scope="col">Country</th>
            <th scope="col">Time</th>
          </tr>
        </thead>

        <tbody>
          {(search ? userList : paginatedData).map((user, i) => {
            return (
              <tr key={i}>
                <td className="text-center">
                  {(currentPage - 1) * itemsPerPage + i + 1}
                </td>
                <td>{user.username ?? "N/A"}</td>
                <td>{user.mobile}</td>
                <td>
                  {/* <FaEye style={{cursor:'pointer'}}/>
                   */}
                  {user?.deposits?.deposits}
                </td>
                <td>{user?.deposits?.withdrawal}</td>
                <td>{user?.deposits?.totalPending?.toFixed(2) ?? 0}</td>
                <td>{user?.deposits?.referralBonus}</td>
                <td>{user.referralId ?? "N/A"}</td>
                <td>{user.country ?? "-"}</td>
                <td>
                  {moment(user.createdAt.seconds * 1000).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="col-lg-12 mt-4 d-flex justify-content-center align-items-center position-relative">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
        <div
          className="btn border p-2"
          style={{ position: "absolute", right: "0px" }}
        >
          Total User : {userList.length}
        </div>
      </div>
    </div>
  );
}

export default UserList;
